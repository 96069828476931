import Vue from 'vue'
import App from './App.vue'
import firebase from 'firebase/app'
// import './registerServiceWorker'
import router from './router'
import store from './store'
import axios from 'axios'
// import VueI18n from 'vue-i18n'
import i18n from './i18n'


// Vue.use(VueI18n)
Vue.use(i18n)

Vue.config.productionTip = false
Vue.prototype.$axios = axios

Vue.use(firebase)
Vue.use(require('vue-moment'))

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount('#app')

i18n.locale = 'zh'