<template>
  <div class="frontStageMain">
    <HeaderFrontstage />
    <main>
      <router-view />
    </main>
    <footer class="ys-footer">
      <div>
        <div class="ys-footer-info">
          <div>
            <div class="logo mb-hide">
              <img class="" src="../assets/logo2.webp" alt="" />
            </div>
          </div>
          <div>
            <div>
              ⓒ {{ new Date().getFullYear() }} 蟬蘊茶 ChanyunTea. All rights
              reserved.
              <span>v0.3.20</span>
            </div>
          </div>
        </div>
        <div class="ys-footer-menu">
          <div>
            <a href="https://www.facebook.com/landfriendlytea" target="_blank">
              <img src="../assets/facebook.svg" loading="lazy" style="background: #fff;" />
            </a>
            <a href="https://lin.ee/JXKmuqc" target="_blank"
              ><img src="../assets/line.svg" loading="lazy"
            /></a>
            <a href="https://shopee.tw/chan_yun_tea" target="_blank">
              <img
                src="https://cfshopeetw-a.akamaihd.net/file/34d7f8da0414d2c6c7c0c2ed1c26a12d"
                loading="lazy"
              />
            </a>
          </div>
          <div>
            <span style="margin-right:10px;"> EMAIL </span>
            <a class="mail" href="mailto:chanyuntea@gmail.com">
              chanyuntea@gmail.com</a
            >
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import HeaderFrontstage from '../components/Header-front'
export default {
  name: 'app',
  components: {
    HeaderFrontstage
  }
}
</script>
