<template>
  <div class="card2">
    <div class="cert-block">
      <h2 v-if="blockTitle">{{ $t(blockTitle) }}</h2>
      <p v-if="blockSubtitle">{{ $t(blockSubtitle) }}</p>
      <div class="flex-block-wrap">
        <div class="flex-block">
          <div class="card" v-for="(item, index) in products" :key="index">
            <img class="card-img" :src="item.img" alt="" @click="openImg(item.img)" loading="lazy" />
            <img class="icon" src="../../src/assets/icon-search.svg" @click="openImg(item.img)" loading="lazy" />
            <div>
              <h3 class="bold">{{ isEn ? item.nameEn : item.name }}</h3>
              <div style="padding: 6px;">
                <div style="line-height: 1.2; font-size: 12px; margin-bottom: 6px;">{{$t('oxidation_level')}}</div>
                <div class="progress-bar">
                  <div v-for="index in 5" :key="index">
                    <div :class="{ 'fill-green': !(index-0.5 > item.ferm), 'last-fill':(index-0.5 === item.ferm), 'first-fill':(index === 1)}" />
                    <div :class="{ 'fill-green': !(index > item.ferm),'last-fill':(index === item.ferm) }" />
                  </div>
                </div>
              </div>
              <div style="padding: 6px;">
                <div style="line-height: 1.2; font-size: 12px; margin-bottom: 6px;">{{$t('roast_level')}}</div>
                <div class="progress-bar">
                  <div v-for="index in 5" :key="index">
                    <div :class="{ fill: !(index-0.5 > item.baking), 'last-fill':(index-0.5 === item.baking), 'first-fill':(index === 1)}" />
                    <div :class="{ fill: !(index > item.baking),'last-fill':(index === item.baking) }" />
                  </div>
                </div>
              </div>
              <div style="margin:10px 0;">
                <a 
                  v-for="priceinfo in item.priceInfo" 
                  :key="priceinfo.weight" 
                  class="btn-border" 
                  :href="item.link"
                  target="_blank"
                >
                  <span>{{priceinfo.weight}}</span>
                  <span> NT{{priceinfo.price.toLocaleString('zh-TW', { style:'currency', currency: 'TWD', maximumFractionDigits: 0 }) }}</span>
                </a>
              </div>
              <div style="height: 34px;">
                <a class="btn-primary pink"
                  :href="item.link"
                  target="_blank"
                  >{{ $t('productInfo') }}</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="imgWrap" v-show="openImgMask" @click="openImg(false)">
      <img class="icon" src="../../src/assets/icon-cancel.svg" loading="lazy" />
      <img class="img" :src="openImgMask" alt="" @click="openImg(false)" loading="lazy" />
    </div>
  </div>
</template>

<script>

export default {
  data() {
    return {
      isEn: false,
      openImgMask: ''
    }
  },
  watch: {
    $route(to) {
      this.isEn = to.params.lang === 'en'
    }
  },
  created() {
    this.isEn = this.$route.params.lang === 'en'
  },
  methods: {
    openImg(img) {
      this.openImgMask = img
    }
    // openImg(item) {
    //   window.open(item.link, '_blank')
    // }
  },
  props: {
    products: {
      required: true
    },
    blockTitle: {
      required: false
    },
    blockSubtitle: {
      required: false
    }
  }
}
</script>

<style lang="scss">
@import '../element-variables.scss';
.card2 {
  .cert-block {
    margin-bottom: 10px;
    &:nth-last-child(2) {
      border-bottom: none;
    }
  }
  h2 {
    padding: 30px 30px 0;
    line-height: 1.5;
    font-size: 22px;
    font-weight: 700;
    letter-spacing: 0.05em;
  }
  h2+p {
    padding: 5px 30px 0 ;
    line-height: 1.5;
    font-size: 16px;
    color: gray;
  }
  .flex-block-wrap {
    width: 100%;
    overflow-x: auto;
  }
  .flex-block {
    display: flex;
    width: -webkit-fill-available;
    position: relative;
    padding: 20px;
    box-sizing: border-box;
    .card {
      display: block;
      position: relative;
      width: 360px;
      min-width: 360px;
      margin: 10px;
      border-radius: 10px;
      &:first-child{
        margin-left: 40px;
      }
      .card-img {
        max-height: 240px;
        width: 360px;
        height: 240px;
        border-radius: 0;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        // border-bottom: 2px solid $gray3;
        cursor: pointer;
        margin-right: 20px;
      }
      .icon {
        position: absolute;
        top: 195px;
        right: 5px;
        height: 40px;
        width: 40px;
        padding: 10px;
        box-sizing: border-box;
        border-radius: 5px;
        border: none;
        background-color: rgba(0, 0, 0, 0.3);
        cursor: pointer;
      }
      > div {
        padding: 12px;
        font-size: 16px;
        h3,
        p{
          margin: 0;
          padding: 6px;
          line-height: 1.2;
          font-size: 12px;
          border-bottom: none;
        }
        a {
          text-decoration: none;
          display: inline-block;
        }
      }
      .btn-primary {
        position: absolute;
        right: 10px;
        bottom: 10px;
      }
      @media (max-width: 768px) {
        width: 252px;
        min-width: 252px;
        margin: 5px;
        border-radius: 10px;
        &:first-child{
          margin-left: 20px;
        }
        .card-img {
          max-height: 168px;
          width: 252px;
          height: 168px;
        }
        .icon {
          position: absolute;
          top: 122px;
        }
      }
    }
  }
}
</style>