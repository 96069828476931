<template>
  <div class="card1">
    <div class="cert-block">
      <h2 v-if="blockTitle">{{ $t(blockTitle) }}</h2>
      <p v-if="blockSubtitle">{{ $t(blockSubtitle) }}</p>
      <div class="flex-block-wrap">
        <div class="flex-block">
          <div class="card" v-for="(cert, index) in certs" :key="index">
            <img class="card-img" :src="cert.img" alt="" loading="lazy" @click="openImg(cert.img)" />
            <img class="icon" src="../../src/assets/icon-search.svg" loading="lazy" @click="openImg(cert.img)" />
            <div v-if="cert.type<4">
              <p class="bold">{{ cert.year }}</p>
              <h3 class="bold">{{ $t(cert.title) }}</h3>
              <p v-if="cert.type===1">
                {{ $t('certId')
                }}<a
                  href="https://www.i-organic.org.tw/farm/MOAF1393/"
                  target="_blank"
                  >{{ cert.id }}</a
                >
              </p>
              <p v-if="cert.type===2&&isEn">
                The sample was tested for
                <span class="blue2 bold"> {{ cert.num }} </span> pesticides and
                the results were found to be non detected with those
                <span class="blue2 bold"> {{ cert.num }} </span> pesticides as
                listed.
              </p>
              <p v-if="cert.type===2&&!isEn">
                本樣品檢測如附錄所列之<span class="blue2 bold">
                  {{ cert.num }} </span
                >項農藥&二硫代胺基甲酸鹽(詳附錄)，均未檢出。
              </p>
              <div v-if="cert.type===3">
                <h3 class="bold">
                  {{ $t('cert_green_target') }}{{ $t(cert.target) }}
                </h3>
                <p>
                  {{ $t('certId')
                  }}<a
                    href="https://toaf.org.tw/conservation/bulletin/list/975-2020-3-31"
                    target="_blank"
                    >{{ cert.id }}</a
                  >
                </p>
              </div>
            </div>
            <div v-else>
              <p class="bold">{{ cert.year }}</p>
              <h3 class="bold">{{ $t(cert.title) }}</h3>
              <p>{{ $t('duration')}}：</p>
              <p>{{ cert.years }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="imgWrap" v-show="openImgMask" @click="openImg(false)">
      <img class="icon" src="../../src/assets/icon-cancel.svg" loading="lazy" />
      <img class="img" :src="openImgMask" alt="" loading="lazy" @click="openImg(false)" />
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      isEn: false,
      openImgMask: '',
    }
  },
  watch: {
    $route(to) {
      this.isEn = to.params.lang === 'en'
    }
  },
  created() {
    this.isEn = this.$route.params.lang === 'en'
  },
  methods: {
    openImg(img) {
      this.openImgMask = img
    }
  },
  props: {
    certs: {
      required: true
    },
    blockTitle: {
      required: false
    },
    blockSubtitle: {
      required: false
    }
  }
}
</script>

<style lang="scss">
@import '../element-variables.scss';
.card1 {
  .cert-block {
    margin-bottom: 10px;
    &:nth-last-child(2) {
      border-bottom: none;
    }
  }
  h2 {
    padding: 30px 30px 0;
    line-height: 1.5;
    font-size: 22px;
    font-weight: 700;
    letter-spacing: 0.05em;
  }
  h2+p {
    padding: 5px 30px 0 ;
    line-height: 1.5;
    font-size: 16px;
    color: gray;
  }
  .flex-block-wrap {
    width: 100%;
    overflow-x: auto;
  }
  .flex-block {
    display: flex;
    width: -webkit-fill-available;
    position: relative;
    padding: 20px;
    box-sizing: border-box;
    .card {
      display: block;
      position: relative;
      width: 240px;
      min-width: 240px;
      margin: 10px;
      border-radius: 20px;
      .card-img {
        max-height: 330px;
        width: 240px;
        height: 330px;
        border-radius: 0;
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
        border-bottom: 2px solid $gray3;
        cursor: pointer;
        margin-right: 20px;
      }
      .icon {
        position: absolute;
        top: 285px;
        right: 5px;
        height: 40px;
        width: 40px;
        padding: 10px;
        box-sizing: border-box;
        border-radius: 5px;
        border: none;
        background-color: rgba(0, 0, 0, 0.3);
        cursor: pointer;
      }
      > div {
        padding: 12px;
        font-size: 16px;
        h3,
        p,
        a {
          margin: 0;
          padding: 6px;
          line-height: 1.2;
          font-size: 12px;
          border-bottom: none;
        }
        a {
          color: rgb(13, 122, 113);
        }
      }
    }
  }
}
</style>
<style lang="sass">

.imgWrap
  display: flex
  position: fixed
  top: 0
  left: 0
  height: 100vh
  width: 100vw
  background-color: rgba(0, 0, 0, 0.5)
  z-index: 5
  .icon
    position: fixed
    top: 20px
    right: 20px
    width: 40px
    height: 40px
    cursor: pointer
    z-index: 99
    // filter: drop-shadow(0 0 10px #cccccc)
  .img
    margin: auto
    position: relative
    max-width: calc(100vw - 40px)
    max-height: calc(100vh - 40px)
    border: 10px solid #fff
</style>
