<template>
  <div class="cert">
    <div v-for="(block, index) in blockData" :key="index">
      <CardOfCert
        :certs="block.certs"
        :blockTitle="block.title"
        :blockSubtitle="block.subtitle"
      />
    </div>
  </div>
</template>

<script>
import green1 from '../../src/assets/cert/green-2020.webp'
import MOA2012 from '../../src/assets/cert/MOA-2012.webp'
import MOA20151 from '../../src/assets/cert/MOA-2015-1.webp'
import MOA20152 from '../../src/assets/cert/MOA-2015-2.webp'
import MOA2020 from '../../src/assets/cert/MOA-2020.webp'
import MOA2021 from '../../src/assets/cert/MOA-2021.webp'
import MOA2023 from '../../src/assets/cert/MOA-2023.jpg'
// import SGS2015Soil from '../../src/assets/cert/SGS-2015-soil.webp'
// import SGS2015Water from '../../src/assets/cert/SGS-2015-water.webp'
import SGS2015Tea from '../../src/assets/cert/SGS-2015-tea.webp'
import SGS2016Tea from '../../src/assets/cert/SGS-2016-tea311.webp'
import SGS2017Tea from '../../src/assets/cert/SGS-2017-bo311.webp'
import SGS2018Tea from '../../src/assets/cert/SGS-2018-tea374.webp'
import SGS2020Tea from '../../src/assets/cert/SGS-2020-tea381.webp'
import Insu2020 from '../../src/assets/cert/insurance-2020.webp'
import Insu2021 from '../../src/assets/cert/insurance-2021.webp'
import CardOfCert from '@/components/cardOfCert.vue'

export default {
  name: 'cert',
  components: { CardOfCert },
  data() {
    return {
      isEn: false,
      openImgMask: '',
      blockData: [
        {
          title: 'cert_name2',
          subtitle: 'moa_title',
          certs: [
            {
              img: MOA2023,
              title: 'cert_name2',
              id: '1-001-111161',
              year: '2023',
              type: 1,
            },
            {
              img: MOA2021,
              title: 'cert_name2',
              id: '1-001-111161',
              year: '2021',
              type: 1,
            },
            {
              img: MOA2020,
              title: 'cert_name2',
              id: '1-001-111161',
              year: '2020',
              type: 1,
            },
            {
              img: MOA20152,
              title: 'cert_name2',
              id: 'MOA1510392',
              year: '2015',
              type: 1,
            },
            {
              img: MOA20151,
              title: 'cert_name2',
              id: 'MOA1510313',
              year: '2015',
              type: 1,
            },
            {
              img: MOA2012,
              title: 'cert_name1',
              id: 'MOA1510313',
              year: '2012',
              type: 1,
            },
          ],
        },
        {
          title: 'sgs_test_report',
          certs: [
            {
              img: SGS2020Tea,
              title: 'sgs_sample_tea',
              year: '2020',
              num: 380,
              type: 2,
            },
            {
              img: SGS2018Tea,
              title: 'sgs_sample_tea',
              year: '2018',
              num: 373,
              type: 2,
            },
            {
              img: SGS2017Tea,
              title: 'sgs_sample_tea',
              year: '2017',
              num: 310,
              type: 2,
            },
            {
              img: SGS2016Tea,
              title: 'sgs_sample_tea',
              year: '2016',
              num: 310,
              type: 2,
            },
            {
              img: SGS2015Tea,
              title: 'sgs_sample_tea',
              year: '2015',
              num: 310,
              type: 2,
            },
          ],
        },
        {
          title: 'cert_green1',
          subtitle: 'toaf_title',
          certs: [
            {
              img: green1,
              title: 'cert_green1',
              id: '20-CK-0304-02',
              year: '2020',
              target: 'cert_green_target_name1',
              type: 3,
              links: [
                {
                  name: '',
                  link: '',
                },
              ],
            },
          ],
        },
        {
          title: 'insurance_title',
          certs: [
            {
              img: Insu2021,
              title: 'insurance_title',
              year: '2021',
              years: '2021-04-23 ~ 2022-04-23',
              type: 4,
            },
            {
              img: Insu2020,
              title: 'insurance_title',
              year: '2020',
              years: '2020-04-06 ~ 2021-04-06',
              type: 4,
            },
          ],
        },
      ],
    }
  },
  watch: {
    $route(to) {
      this.isEn = to.params.lang === 'en'
    },
  },
  created() {
    this.isEn = this.$route.params.lang === 'en'
  },
  methods: {
    goto(name) {
      this.$router.push({ name })
    },
    openImg(img) {
      this.openImgMask = img
    },
  },
}
</script>
<style lang="scss">
@import '../element-variables.scss';
.cert {
  padding-top: 80px;
  .card1 {
    .flex-block {
      padding: 20px calc((100vw - 1300px) / 2);
    }
    .cert-block {
      > h2,
      > p {
        padding-left: calc(10px + (100vw - 1300px) / 2);
      }
    }
    @media (max-width: 1300px) {
      .flex-block {
        padding: 20px;
      }
      .cert-block {
        > h2,
        > p {
          padding-left: 30px;
        }
      }
    }
  }
}
</style>
