<template>
  <div class="home">
    <div class="videoWrapper">
      <img
        class="desk-hide"
        v-if="isMb"
        src="../../src/assets/ChanYunTea_bg.webp"
        alt=""
      />
      <video
        v-else
        width="320"
        height="240"
        loop
        autoplay
        muted
        preload="none"
        playsinline
        class="mb-hide"
        poster="../../src/assets/ChanYunTea_bg.webp"
      >
        <source src="../../src/assets/ChanYunTea.mp4" type="video/mp4" />
      </video>
      <div class="videoTextCard" style="max-width: 840px">
        <div class="h2-title" :class="{ isEn: isEn }">
          <h2>{{ $t('organic') }}</h2>
          <h2>{{ $t('natural') }}</h2>
          <h2>{{ $t('handplucked') }}</h2>
        </div>
        <h3>{{ $t('homeSubtitle1') }}</h3>
        <p>{{ $t('homeSubtitle2') }}</p>
      </div>
    </div>
    <div v-for="(block, index) in aboutLang" :key="index">
      <div class="col-center-wrap">
        <div>
          <div class="col-wrap">
            <img class="col-wrap-img" :src="block.img" alt="" loading="lazy" />
            <div>
              <h3>{{ block.title }}</h3>
              <p v-for="(p, pIndex) in block.content" :key="pIndex">{{ p }}</p>
            </div>
          </div>
        </div>
      </div>
      <CardOfCert :certs="certs" v-if="index === 1" />
    </div>
    <CardOfProducts :products="products" />
    <div class="col-center-wrap">
      <div>
        <div class="col-wrap contact">
          <div>
            <h2>{{ $t('contact_us_title') }}</h2>
            <p>
              {{ $t('contact_us_text') }}
            </p>
          </div>
          <div></div>
        </div>
        <div class="form">
          <div>
            <div>
              <h3>{{ $t('location_title') }}</h3>
              <div class="col-title">Shopee 蝦皮賣場</div>
              <div>
                <a href="https://shopee.tw/chan_yun_tea" target="_blank">
                  <img
                    src="https://cfshopeetw-a.akamaihd.net/file/c7a34c461bb202d8c3dc60569dba19b2"
                    loading="lazy"
                  />
                </a>
              </div>
              <div class="col-title">Facebook</div>
              <div>
                <iframe
                  src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Flandfriendlytea%2F&tabs=timeline&width=600&height=600&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId=346606602341306"
                  width="100%"
                  height="500"
                  style="border: none; overflow: hidden"
                  scrolling="no"
                  frameborder="0"
                  allowfullscreen="true"
                  allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
                ></iframe>
              </div>
              <div class="col-title">
                {{ $t('location_text1') }}
                <span class="red1">{{ $t('location_text4') }}</span>
              </div>
              <div class="col-content">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3677.0741683299902!2d121.09980955017843!3d22.836745328905664!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x346fba8b53c1f5bf%3A0xaf727d0eb3206bb0!2z5b6h5ZOB6LOe5YGl5bq36I6K5ZyS!5e0!3m2!1szh-TW!2stw!4v1612924561082!5m2!1szh-TW!2stw"
                  width="100%"
                  height="300"
                  frameborder="0"
                  style="border: 0; margin-bottom: 20px"
                  allowfullscreen=""
                  aria-hidden="false"
                  tabindex="0"
                ></iframe>
              </div>
              <div class="col-title">
                {{ $t('location_text2') }}
                <span class="red1">{{ $t('location_text4') }}</span>
              </div>
              <div class="col-content">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3678.731022456688!2d121.1264192499939!3d22.775361285005584!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x346fb9790602424d%3A0x78188969290ceaed!2zOTUw5Y-w5p2x57ij5Y-w5p2x5biC5pu055Sf6LevMTIwMOiZnw!5e0!3m2!1szh-TW!2stw!4v1612926351249!5m2!1szh-TW!2stw"
                  width="100%"
                  height="300"
                  frameborder="0"
                  style="border: 0; margin-bottom: 20px"
                  allowfullscreen=""
                  aria-hidden="false"
                  tabindex="0"
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import img1 from '../../src/assets/ChanyunTea_01.webp'
import img2 from '../../src/assets/ChanyunTea_02.webp'
import img3 from '../../src/assets/ChanyunTea_03.webp'
import img4 from '../../src/assets/ChanyunTea_04.webp'
import img5 from '../../src/assets/ChanyunTea_05.webp'
import MOA2023 from '../../src/assets/cert/MOA-2023.jpg'
import Price2023_1 from '../../src/assets/cert/price-2023-1.jpg'
import Price2023_2 from '../../src/assets/cert/price-2023-2.jpg'
import Price2023_3 from '../../src/assets/cert/price-2023-3.jpg'
import green1 from '../../src/assets/cert/green-2020.webp'
import SGS2020Tea from '../../src/assets/cert/SGS-2020-tea381.webp'
import Insu2021 from '../../src/assets/cert/insurance-2021.webp'
import chanyuntea_blacktea_01 from '../../src/assets/products/chanyuntea_blacktea_01.webp'
import chanyuntea_blackOolong_01 from '../../src/assets/products/chanyuntea_blackOolong_01.webp'
import chanyuntea_Oolong_01 from '../../src/assets/products/chanyuntea_Oolong_01.webp'
import chanyuntea_beauty_01 from '../../src/assets/products/chanyuntea_beauty_01.webp'

import CardOfCert from '@/components/cardOfCert.vue'
import CardOfProducts from '@/components/cardOfProducts.vue'

export default {
  name: 'home',
  components: {
    CardOfCert,
    CardOfProducts,
  },
  data() {
    return {
      isEn: false,
      isMb: true,
      aboutLang: {},
      aboutzh: [
        {
          title: '來自台東卑南的實在小農',
          content: [
            '我們的茶園位在台東縱谷的美農高台上，中央山脈及海岸山脈環繞，空氣清新，水源透澈乾淨，茶園占地僅 1.5 公頃，因此照顧可面面俱到，以草生栽培的有機農法，堅持自然實在的品質。',
          ],
          img: img1,
        },
        {
          title: '有機  純淨  無負擔',
          content: [
            '通過 MOA 嚴格有機認證，經 381 項農藥無殘留嚴格檢測。',
            '我們藉由草生栽培的有機農法來涵養水土，保持地力營養，並提供微生物友好的生長環境，使得茶園生態長期下來形成自然的平衡，我們不勉強土地，也不勉強作物，茶生長的自在舒服，喝起來也就溫和順口。',
            '◆ 獲獎紀錄：',

            '全國有機袋茶分類分級TAGs評鑑',
            '- 2023 🥈焙香型烏龍茶 精選（紅烏龍）',
            '- 2023 🥉清香型烏龍茶 優選',
            '- 2023 🥉紅茶 優選',

            '全國有機茶分類分級TAGs評鑑',
            '- 2023 🥈清香型烏龍茶 優選',
            '- 2023 🥈紅茶茶 優選',
            '- 2022 🥇清香型烏龍茶 特選（最高榮譽）',
            '- 2021 🥈清香型烏龍茶 銀蛙選',
            '- 2020 🥉有機烏龍茶 銅蛙選',

            '臺東嚴選Bravo Design',
            '- 2021 🏅產品包裝設計獎 入選',

            '台東好物年度大賞',
            '- 2018 🏅 特色食品 金禮獎',
          ],
          img: img2,
        },
        {
          title: '小綠葉蟬著涎帶來的蜜香底蘊',
          content: [
            '小綠葉蟬於葉面上著涎，會使茶樹開啟自癒及防禦機制，增加了「茶多元酚類」及「茶單寧」的濃度與豐富度，使茶香蘊含了更多層次的蜜香風味。這便是我們取名為「蟬蘊茶」的由來，唯有藉由草生栽培提供友善的生態環境，我們才能喝到這壺芬芳蘊藉的好茶。',
          ],
          img: img3,
        },
        {
          title: '職人揀選  手工採茶',
          content: [
            '一年採收 5 至 6 次，皆排除萬難延請台東鹿野的採茶班，個個都是4、50 年老經驗的專業採茶職人，細心揀選品質良好、葉片完整 的一心二葉、一心三葉嫩葉茶菁。',
          ],
          img: img4,
        },
        {
          title: '台東有機蜜香好茶',
          content: [
            '調理生活狀態，',
            '維持心情節奏的健康。',
            '讓在台東緩慢生長的茶香滋味，',
            '陪你度過每個明朗的夏夜和溫暖的冬天。',
          ],
          img: img5,
        },
      ],
      abouten: [
        {
          title: 'Taitung Beinan Tea Farmer',
          content: [
            `Our tea plantation is lying on the Gaotai highland in the East Rift Valley of Taitung which is surrounded by Central Mountain Range and Hai'an Range.The air is fresh, the water is clean, and the 1.5 hectare land is under good care by using organic sod culture farming.`,
          ],
          img: img1,
        },
        {
          title: 'Organic / Natural / Pesticide & Chemical Free',
          content: [
            'Our tea is MOA certified organic.',
            'We don’t use any herbicide, pesticide and chemical fertilizer from the beginning and through out all the process.',
            'In sod culture farming, grasses are allowed to grow to prevent soil erosion, maintain moisture and nutrient in soil, and to create a safe and friendly environment for small creatures.',
            'By letting nature run its course, you will taste the leisure and comfortable inside the sweet and smooth aroma.',
          ],
          img: img2,
        },
        {
          title: 'Honey flavor caused by little green leafhopper’s bite',
          content: [
            'When a little green leafhopper bites into a tea leaf, the leaf starts its healing and defense mechanism, creating more “tea polyphenols” and “catechins” that bring you a tasty honey flavor cup of tea.',
            'Therefore, we named the brand “Chan Yun Tea”, which means the tea was raised by Cicadas, to give credit to these tiny farmer.',
          ],
          img: img3,
        },
        {
          title: 'Plucking & Sorting by Professional Spirit and Hand',
          content: [
            'We harvest 5-6 times each year. We ask Luyeh professional plucking team for help every time. Almost everyone in the team has over 40-50 years of experience in plucking tea. They can pluck high quality and young one-tip two-leaf(or three) in perfect shape with remarkably agile move.',
          ],
          img: img4,
        },
        {
          title: 'Drip a nice cup of Organic Honey Tea',
          content: [
            'Let a sip of hot nice Taitung tea soothes all your troubles away.It slow down your pace just like you are living in Taitung.',
            'In every clear summer night, and every comfortable warm winter days, let a nice cup of our Organic Honey Tea be your company!',
          ],
          img: img5,
        },
      ],
      certs: [
        {
          img: MOA2023,
          title: 'cert_name2',
          id: '1-001-111161',
          year: '2023',
          type: 1,
        },
        {
          img: Price2023_1,
          title: 'prize_title',
          year: '2023',
          type: 0,
        },
        {
          img: Price2023_2,
          title: 'prize_title',
          year: '2023',
          type: 0,
        },
        {
          img: Price2023_3,
          title: 'prize_title',
          year: '2023',
          type: 0,
        },
        {
          img: Insu2021,
          title: 'insurance_title',
          year: '2021',
          years: '2021-04-23 ~ 2022-04-23',
          type: 4,
        },
        {
          img: green1,
          title: 'cert_green1',
          id: '20-CK-0304-02',
          year: '2020',
          target: 'cert_green_target_name1',
          type: 3,
          links: [
            {
              name: '',
              link: '',
            },
          ],
        },
        {
          img: SGS2020Tea,
          title: 'sgs_sample_tea',
          year: '2020',
          num: 380,
          type: 2,
        },
      ],
      products: [
        {
          name: '【蟬蘊茶】台東有機蜜香紅茶',
          nameEn: '【 ChanYunTea 】Taitung Organic Honey Black Tea',
          link: 'https://shopee.tw/product/103986166/2755071027/',
          id: '2755071027',
          img: chanyuntea_blacktea_01,
          ferm: 5,
          baking: 1,
          priceInfo: [
            // {
            //   weight: '35g',
            //   price: 300,
            // },
            // {
            //   weight: '50g',
            //   price: 400,
            // },
            {
              weight: '75g',
              price: 580,
            },
            {
              weight: '茶包 2.5g x 15入',
              price: 530,
            },
          ],
        },
        {
          name: '【蟬蘊茶】台東有機蜜香烏龍',
          nameEn: '【 ChanYunTea 】Taitung Organic Honey Oolong Tea',
          link: 'https://shopee.tw/product/103986166/2755123766/',
          id: '2755123766',
          img: chanyuntea_Oolong_01,
          ferm: 2.5,
          baking: 2,
          priceInfo: [
            // {
            //   weight: '50g',
            //   price: 480,
            // },
            {
              weight: '75g',
              price: 680,
            },
            {
              weight: '150g',
              price: 1320,
            },
            {
              weight: '茶包 3g x 15入',
              price: 640,
            },
          ],
        },
        {
          name: '【蟬蘊茶】台東有機蜜香紅烏龍茶',
          nameEn: '【 ChanYunTea 】Taitung Organic Honey Black Oolong',
          link: 'https://shopee.tw/product/103986166/2755109706/',
          id: '2755109706',
          img: chanyuntea_blackOolong_01,
          ferm: 3.5,
          baking: 2,
          priceInfo: [
            // {
            //   weight: '50g',
            //   price: 400,
            // },
            {
              weight: '75g',
              price: 580,
            },
            {
              weight: '150g',
              price: 1120,
            },
            {
              weight: '茶包 3g x 15入',
              price: 580,
            },
          ],
        },
        {
          name: '【蟬蘊茶】台東有機蟬蘊美人',
          nameEn: '【 ChanYunTea 】Taitung Organic Beauty Oolong',
          link: 'https://shopee.tw/product/103986166/5953488504/',
          id: '5953488504',
          img: chanyuntea_beauty_01,
          ferm: 3,
          baking: 2,
          priceInfo: [
            // {
            //   weight: '30g',
            //   price: 300,
            // },
            // {
            //   weight: '50g',
            //   price: 480,
            // },
            {
              weight: '75g',
              price: 680,
            },
          ],
        },
      ],
    }
  },
  watch: {
    $route(to) {
      this.isEn = to.params.lang === 'en'
      this.aboutLang = to.params.lang === 'en' ? this.abouten : this.aboutzh
    },
  },
  created() {
    this.isEn = this.$route.params.lang === 'en'
    this.aboutLang =
      this.$route.params.lang === 'en' ? this.abouten : this.aboutzh
    this.onresize()
  },
  mounted() {
    window.addEventListener('resize', this.onresize)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onresize)
  },
  methods: {
    onresize() {
      this.isMb = window.innerWidth < 768
    },
    goto(name) {
      this.$router.push({ name })
    },
  },
}
</script>
<style lang="scss" scope>
@import '../element-variables.scss';
.home {
  .card1 {
    margin-top: 20px;
    .flex-block {
      padding: 20px calc((100vw - 1024px) / 2);
    }
  }
  .card2 {
    margin-top: 20px;
    .flex-block {
      padding: 20px calc((100vw - 1520px) / 2);
    }
  }
}
.col-wrap {
  margin-top: 100px;
  padding: 0 60px;
  max-width: 1024px;
  box-sizing: border-box;
  div {
    h3 {
      margin-block-start: 0;
      margin-block-end: 0;
      padding: 40px 0 16px;
      font-size: 1.7em;
      font-weight: 700;
      letter-spacing: 0.05em;
      border-bottom: 1px solid rgba(0, 0, 0, 0.15);
      line-height: 1.4;
    }
    p {
      margin-block-start: 0;
      margin-block-end: 0;
      margin-top: 15px;
      padding: 0;
      line-height: 1.8;
      font-size: 18px;
      letter-spacing: 0.05em;
      color: #191919;
    }
  }
  .col-wrap-img {
    width: 100%;
    max-width: 100%;
    border-radius: 10px;
  }
  @media (max-width: 768px) {
    margin-top: 60px;
    padding: 0 30px;
    div {
      h3 {
        font-size: 20px;
        padding: 20px 0;
      }
      p {
        margin-top: 13px;
        font-size: 14px;
      }
    }
  }
}
.contact {
  padding-top: 40px;
  background: url('../../src/assets/ChanyunTea_08.webp');
  background-size: cover;
  background-position-y: 50%;
  color: #fff;
  display: flex;
  padding-bottom: 100px;
  &.col-wrap {
    div,
    p {
      color: #fff;
    }
  }
  > div {
    flex: 1;
    &:last-child {
      text-align: right;
    }
  }
  img {
    margin-right: 20px;
    width: 500px;
  }
  @media (max-width: 900px) {
    display: block;
    > div:last-child {
      text-align: center;
    }
    img {
      width: 90%;
      margin-right: 0;
    }
  }
}
.form {
  display: flex;
  padding: 0 60px;
  position: relative;
  top: -60px;
  > div {
    padding: 20px;
    background: #fff;
    border-radius: 10px;
    box-shadow: 0px 4px 24px rgba(18, 29, 51, 0.08);
    &:first-child {
      flex: 1;
      margin-right: 20px;
    }
    &:last-child {
      width: 260px;
    }
  }
  h3 {
    color: $brown2;
    margin-top: 0;
    margin-bottom: 0;
    border-bottom: solid 1px $gray2;
    padding-bottom: 10px;
    font-size: 20px;
  }
  .col-title {
    line-height: 1.4;
    font-size: 16px;
    font-weight: 700;
    padding: 20px 0;
  }
  .col-content {
    cursor: pointer;
    font-size: 14px;
    text-decoration: none;
  }
  @media (max-width: 900px) {
    display: block;
    padding: 0;
    > div {
      margin: auto;
      box-sizing: border-box;
      width: calc(100% - 40px);
    }
    > div:last-child {
      width: calc(100% - 40px);
      position: relative;
      top: 20px;
    }
  }
}
</style>
<style lang="scss" scoped>
.videoWrapper {
  position: relative;
  height: 100vh;
  width: 100%;
  overflow: hidden;
  display: flex;
  video,
  img {
    position: absolute;
    left: 50%;
    top: 0;
    transform: translateX(-50%);
    width: auto;
    height: auto;
    min-height: 100vh;
    min-width: 100vw;
  }
  img {
    top: 50%;
    left: 60%;
    bottom: 0;
    transform: translate(-60%, -50%);
  }
  .videoTextCard {
    position: relative;
    margin: auto;
    padding: 80px 80px 0 80px;
    width: fit-content;
    // background: rgba(255, 255, 255, 0.2);
    // backdrop-filter: blur(10px);
    .h2-title,
    h3,
    p {
      margin: 10px;
      color: #fff;
      text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
    }
    .h2-title {
      padding-bottom: 20px;
      border-bottom: solid 5px #fff;
      text-align: center;
      h2 {
        margin: 0 10px;
        font-size: 80px;
        display: inline-block;
      }
      &.isEn {
        text-align: left;
        display: block;
        h2 {
          display: block;
          margin: 0;
          font-size: 48px;
          font-weight: 900;
          font-weight: bold;
          line-height: 1.2;
        }
      }
    }
    h3 {
      font-size: 30px;
      padding-top: 20px;
      font-weight: 700;
    }
    p {
      font-size: 22px;
      font-weight: 600;
    }
    @media (max-width: 768px) {
      margin-bottom: 130px;
      text-align: center;
      .h2-title,
      h3,
      p {
        margin: 6px 20px;
        line-height: 1.4;
      }
      .h2-title {
        padding-bottom: 10px;
        h2 {
          font-size: 40px;
        }
      }
      h3 {
        text-align: left;
        font-size: 20px;
        padding-top: 10px;
      }
      p {
        text-align: left;
        font-size: 14px;
      }
    }

    @media (max-width: 430px) {
      padding: 80px 2px 0 2px;
      .h2-title {
        h2 {
          font-size: 40px;
          margin: 0 6px;
        }
        &.isEn {
          h2 {
            font-size: 34px;
          }
        }
      }
      h3 {
        font-size: 18px;
      }
    }
  }
}
</style>
